import React, {useEffect} from 'react'
import { Box, Stack, Button, Typography, Grid, useMediaQuery } from '@mui/material'
import IntroBgImg from "../../Icons/IntroBgImg.svg"
import AboutUsImg from "../../Icons/AboutUsImg.svg"
import PlantsImg from "../../Icons/Intro/PlantsImg.svg"
import { ReactComponent as OurMissionIcon } from "../../Icons/Intro/OurMissionIcon.svg"
import { ReactComponent as ValuePropositionIcon } from "../../Icons/Intro/ValuePropositionIcon.svg"
import { ReactComponent as CommityValueIcon } from "../../Icons/Intro/CommityValueIcon.svg"
import AffordabilityImg from "../../Icons/Intro/Affordability.svg"
import ConvenienceImg from "../../Icons/Intro/Convenience.svg"
import ComfortableImg from "../../Icons/Intro/Comfortable.svg"
import { DocumentTitleChange } from '../../CommonComponents/Title'
// import IntroBgImg from "../../assets/intro.webp"
// import AboutUsImg from "../../assets/hand.webp"

export default function Intro() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width:960px)');
    // useEffect(() => {
    //     DocumentTitleChange('Intro')
    // }, [])
    return (
        <Box>
            {isMobile || isTablet ? (<>
                <Box component="img" src={IntroBgImg} sx={{ objectFit: "cover"}}></Box>
                <Stack p={5} gap={4} direction="column" alignItems="center" justifyContent="center" sx={{ background: "#003893", top: "13%", right: "5%", }}>
                    <Typography p={2} width="fit-content" variant="h6_1" color="#FFFFFF" border="1px solid #FFFFFF" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">OUR STORY</Typography>
                    <Typography sx={{ lineHeight: "28px" }} variant="h9_4" color="#FFFFFF">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis,
                        leo odio faucibus metus, tempus accumsan ipsum purus ut libero. Nam iaculis leo eu metus feugiat maximus. Sed eu massa pretium, bibendum elit gravida, condimentum sem.
                    </Typography>
                    <Typography sx={{ lineHeight: "28px" }} variant="h9_4" color="#FFFFFF">Vestibulum aliquet lacus maximus enim convallis, nec tempus ex interdum. Mauris in magna ut odio laoreet rhoncus at ac sem.
                        Phasellus sit amet elit magna. Nulla tempor id mauris id bibendum. Fusce volutpat tortor at justo molestie, efficitur imperdiet sapien ultrices. Morbi finibus mauris quis pretium aliquam. Sed vel quam a ipsum gravida suscipit.
                        Nullam a nisl ac nibh fermentum eleifend eget vel sapien. Sed fringilla metus sed odio sagittis, id vehicula orci faucibus. Suspendisse quis tortor vitae nisl condimentum condimentum a eget libero. Etiam sem eros, efficitur et hendrerit vel, efficitur eget ligula. Maecenas porttitor rutrum tincidunt.
                        Sed malesuada consequat bibendum. Nunc sit amet pharetra orci.</Typography>
                </Stack>
            </>) : (<>
                <Stack pb={7} px={7} sx={{ position: "relative" }}>
                <Box sx={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                }}>
                    <Box component="img" src={IntroBgImg} sx={{ objectFit: "cover"}}></Box>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            background: "linear-gradient(to left, rgb(255, 255, 255) 35%, rgba(0, 0, 0, 0) 61%)",
                        }}
                    />
                </Box>
                <Stack p={5} gap={4} direction="column" alignItems="center" justifyContent="center" sx={{ background: "#003893", maxWidth: "800px", borderRadius: "20px", position: "absolute", top: "13%", right: "5%", }}>
                    <Typography p={2} width="fit-content" variant="h6_1" color="#FFFFFF" border="1px solid #FFFFFF" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">OUR STORY</Typography>
                    <Typography sx={{ lineHeight: "28px" }} variant="h9_4" color="#FFFFFF">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis,
                        leo odio faucibus metus, tempus accumsan ipsum purus ut libero. Nam iaculis leo eu metus feugiat maximus. Sed eu massa pretium, bibendum elit gravida, condimentum sem.
                    </Typography>
                    <Typography sx={{ lineHeight: "28px" }} variant="h9_4" color="#FFFFFF">Vestibulum aliquet lacus maximus enim convallis, nec tempus ex interdum. Mauris in magna ut odio laoreet rhoncus at ac sem.
                        Phasellus sit amet elit magna. Nulla tempor id mauris id bibendum. Fusce volutpat tortor at justo molestie, efficitur imperdiet sapien ultrices. Morbi finibus mauris quis pretium aliquam. Sed vel quam a ipsum gravida suscipit.
                        Nullam a nisl ac nibh fermentum eleifend eget vel sapien. Sed fringilla metus sed odio sagittis, id vehicula orci faucibus. Suspendisse quis tortor vitae nisl condimentum condimentum a eget libero. Etiam sem eros, efficitur et hendrerit vel, efficitur eget ligula. Maecenas porttitor rutrum tincidunt.
                        Sed malesuada consequat bibendum. Nunc sit amet pharetra orci.</Typography>
                </Stack>
            </Stack>
            </>)}
            

            {/* About Us */}

            <Box sx={{ backgroundColor: "#E9F8F9", p :{xs : 3, lg : 7}, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography p={2} mt={5} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">ABOUT US</Typography>
                <Stack sx={{display : 'flex', flexDirection : 'row', flexWrap : {xs : 'wrap', lg : 'nowrap'}, gap: '50px'}} mt={5}>
                    <Box component="img" src={AboutUsImg} sx={{ width: "100%", height: "100%" }}></Box>
                    <Stack mt={0} rowGap={4} justifyContent="center">
                        <Typography sx={{ lineHeight: "28px" }} variant="h9_4" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis,
                            leo odio faucibus metus, tempus accumsan ipsum purus ut libero. Nam iaculis leo eu metus feugiat maximus. Sed eu massa pretium, bibendum elit gravida, condimentum sem.
                        </Typography>
                        <Typography sx={{ lineHeight: "28px" }} variant="h9_4" >Vestibulum aliquet lacus maximus enim convallis, nec tempus ex interdum. Mauris in magna ut odio laoreet rhoncus at ac sem.
                            Phasellus sit amet elit magna. Nulla tempor id mauris id bibendum. Fusce volutpat tortor at justo molestie, efficitur imperdiet sapien ultrices. Morbi finibus mauris quis pretium aliquam. Sed vel quam a ipsum gravida suscipit.
                            Nullam a nisl ac nibh fermentum eleifend eget vel sapien. Sed fringilla metus sed odio sagittis, id vehicula orci faucibus. Suspendisse quis tortor vitae nisl condimentum condimentum a eget libero. Etiam sem eros, efficitur et hendrerit vel, efficitur eget ligula. Maecenas porttitor rutrum tincidunt.
                            Sed malesuada consequat bibendum. Nunc sit amet pharetra orci.</Typography>
                    </Stack>
                </Stack>
            </Box>

           

           
                <Stack mt={{xs : 0, sm : 2, lg : 5}} p={{xs : 3, lg : 7}} direction={{xs : 'column-reverse', lg : 'row'}} columnGap={10}>
                <Stack justifyContent="center" gap={{xs : 3.5, lg: 2.5}} sx={{mt : {xs : 0, sm : 4,lg : 0}}}>
                    <Stack gap={{xs : 1.5 , lg :1}}>
                        <Typography variant="h3" sx={{ display: "flex", gap: "20px", fontWeight: 700, alignItems : 'center' }}><OurMissionIcon />  Our Mission</Typography>
                        <Typography variant="h7_3">To provide the love and support our students need to develop their potential to the fullest.To provide the love and support our students need to develop their potential to the fullest.</Typography>
                    </Stack>
                    <Stack gap={{xs : 1.5 , lg :1}}>
                        <Typography variant="h3" sx={{ display: "flex", gap: "20px", fontWeight: 700, alignItems : 'center' }}><ValuePropositionIcon /> Value Proposition</Typography>
                        <Typography variant="h7_3">To provide the love and support our students need to develop their potential to the fullest.</Typography>
                    </Stack>
                    <Stack gap={{xs : 1.5 , lg :1}}>
                        <Typography variant="h3" sx={{ display: "flex", gap: "20px", fontWeight: 700, alignItems : 'center' }}><CommityValueIcon />Community Value</Typography>
                        <Typography variant="h7_3">To provide the love and support our students need to develop their potential to the fullest. </Typography>
                    </Stack>
                </Stack>
                <Box component="img" src={PlantsImg} sx={{ width: {xs : '500px', sm : '100%', lg : '500px'}, height: "450px", objectFit : {xs : 'initial', sm : 'cover', lg : 'initial'} }}></Box>
            </Stack>
            

            {/* Why choose Us */}

            <Box sx={{ backgroundColor: "#E9F8F9", p: {xs : 2, lg : 7}, display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                <Typography sx={{mt : 4}} p={2} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">WHY CHOOSE US</Typography>
                    
                    <Box sx={{display : 'flex', justifyContent : 'space-between', flexDirection : {xs : 'column', lg : 'row'}, mt : 5}}>
                    <Box px={{xs : 0, lg: 3}}  justifyContent="center" textAlign="center">
                        <Box component="img" sx={{width : '100%'}} src={AffordabilityImg}></Box>
                        <Typography variant="h3" pb={{xs : 0.3, lg :1}} my={2} sx={{ width: "fit-content", fontWeight: 700, borderBottom: "3px solid grey" }}>Affordability</Typography>

                        <Typography px={0} textAlign='justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis, leo odio faucibus metus, tempus accumsan ipsum purus ut libero.</Typography>
                    </Box>
                    <Box mt={{xs : 5, lg : 0}} px={{xs : 0, lg: 3}} justifyContent="center" textAlign="center">
                        <Box component="img" sx={{width : '100%'}} src={ConvenienceImg}></Box>
                        <Typography variant="h3" pb={{xs : 0.3, lg :1}} my={2} sx={{ width: "fit-content", fontWeight: 700, borderBottom: "3px solid grey" }}>Convenience</Typography>

                        <Typography px={0} textAlign='justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis, leo odio faucibus metus, tempus accumsan ipsum purus ut libero.</Typography>
                    </Box>
                    <Box mt={{xs : 5, lg : 0}} px={{xs : 0, lg: 3}} justifyContent="center" textAlign="center">
                        <Box component="img" sx={{width : '100%'}} src={ComfortableImg}></Box>
                        <Typography variant="h3" pb={{xs : 0.3, lg :1}} my={2} sx={{ width: "fit-content", fontWeight: 700, borderBottom: "3px solid grey" }}>Comfortable</Typography>

                        <Typography px={0} textAlign='justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur ultrices ornare. Sed consectetur massa tellus, in auctor diam euismod vitae. Donec facilisis, tortor ac auctor lobortis, leo odio faucibus metus, tempus accumsan ipsum purus ut libero.</Typography>
                    </Box>
               

                    </Box>
            </Box>

            <Stack mb={7} mt={7} direction={{xs : 'column' , lg : "row"}} justifyContent="space-between" alignItems="center" mx={{xs : 2, lg : 7}} p={4} sx={{ backgroundColor: "#1A2461", borderRadius: "20px" }}>
                <Stack gap="10px">
                    <Typography p={1} width="fit-content" variant="h9_5" color="#FFFFFF" border="1px solid #FFFFFF" padding="10px 30px" borderRadius="50px" letterSpacing="1.1px">Intake application</Typography>
                    <Typography variant="h2" color="#FFFFFF" sx={{ fontWeight: 500 }}>Start a new life in Singapore!</Typography>
                    <Typography variant="h4_2" color="#FFFFFF">We make it happen</Typography>
                </Stack>
                <Button variant="contained" sx={{width : {xs : '100%', sm : '55%', lg : '12%'}, marginTop : {xs : '15px', lg : 0}}}>Fill form</Button>
            </Stack>
        </Box >
    )
}
