import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";

export default function Hostellist() {
  const navigate = useNavigate();
  let deleteData = {
    label: "Confirm Delete Hostel ",
    descriptionOne: "Please confirm that you wish to delete the Hostel.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#912018",
      text: "Confirm",
    },
  };

  const [hostelData, setHostelData] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const getAllHostel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getAllhostel`
      );
      console.log(response);
      setHostelData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setDeleteModal(null);
    setDeleteId(null);
  };

  const deleteHostel = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}deleteHostel/${deleteId}`
      );
      getAllHostel();
      setDeleteModal(null);
      setDeleteId(null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllHostel();
  }, []);

  const handleSeeMore = (description) => {
    setSelectedDescription(description);
  };

  return (
    <>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteHostel}
          closeFunction={handleClose}
        />
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h7_1">Hostel</Typography>
        <Button
          onClick={() => navigate("/Admin/HostelAdd")}
          variant="contained"
        >
          Add
        </Button>
      </Stack>
      <Divider sx={{ mb: 3, mt: 2 }} />
      <TableContainer sx={{ borderRadius: "8px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Image
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h9_1" color="#333333">
                  Title
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hostelData.map((item, index) => (
              <TableRow
                key={item.name}
                sx={{ borderTop: ".5px solid #737373" }}
              >
                <TableCell>
                  <Box
                    component="img"
                    src={
                      process.env.REACT_APP_FILE_PATH + item?.images?.[0]?.src
                    }
                    sx={{ width: "250px" }}
                  ></Box>
                </TableCell>
                <TableCell sx={{ minWidth: "200px" }}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.title || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ maxWidth: "200px" }}>
                  <Button
                    onClick={() => handleSeeMore(item?.description)}
                    variant="contained"
                  >
                    See Description
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => navigate(`/Admin/edit-hostel/${item.id}`)}
                    sx={{ marginRight: "10px" }}
                  >
                    <EditIcon fill="black" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteId(item.id);
                      setDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={!!selectedDescription}
        onClose={() => setSelectedDescription("")}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Full Description</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <Typography variant="body1">{parse(selectedDescription)}</Typography>
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button
            onClick={() => setSelectedDescription("")}
            color="primary"
            variant="contained"
            sx={{ margin: "10px", padding: "10px 20px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
