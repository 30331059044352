import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as DeleteIcon } from "../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../Icons/EditIcon.svg";
import AlertModal from "../../CommonComponents/AlertModal";

export default function Services() {
  const navigate = useNavigate();

  const [showServices, setShowServices] = useState(() => {
    const localValue = localStorage.getItem("serve");
    return localValue !== null ? JSON.parse(localValue) : true;
  });

  const [serviceData, setServiceData] = useState([]);
  const [otherService, setOtherService] = useState([]);
  const [loading, setLoading] = useState(true);

  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  let deleteData = {
    label: showServices
      ? "Confirm Delete Service"
      : "Confirm Delete Other Service",
    descriptionOne: showServices
      ? "Please confirm that you wish to delete the service."
      : "Please confirm that you wish to delete the other service.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#912018",
      text: "Confirm",
    },
  };

  useEffect(() => {
    localStorage.setItem("serve", showServices);
  }, [showServices]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getAllServices`
      );
      console.log("Get All Services:", response.data.data);
      setServiceData(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchOtherServiceData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-other-services`
      );
      console.log("Get All Other Services:", response.data.data);
      setOtherService(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchOtherServiceData();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const handleClose = () => {
    setDeleteModal(null);
    setDeleteId(null);
  };

  const deleteOtherService = async () => {
    try {
      if (showServices === true) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}deleteService/${deleteId}`
        );
        fetchData();
        setDeleteModal(null);
        setDeleteId(null);
      } else if (showServices === false) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}delete-other-service/${deleteId}`
        );
        fetchOtherServiceData();
        setDeleteModal(null);
        setDeleteId(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  return (
    <>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteOtherService}
          closeFunction={handleClose}
        />
      )}

      <Box mb={30}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
            Services
          </Typography>
          {/* <Button onClick={() => navigate('/Admin/add-service')} variant="contained">Add</Button> */}
        </Stack>
        <Divider sx={{ mb: 3, mt: 2 }} />
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box
            onClick={() => setShowServices(true)}
            sx={{
              width: "100%",
              borderTop: showServices ? "3px solid #27bac4" : "3px solid gray",
              borderRadius: "0px",
              textAlign: "left",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              padding: "3px",
              transition: "ease-in",
              transitionDuration: "250ms",
            }}
          >
            <Typography
              sx={{
                pt: 0,
                color: showServices ? "#27bac4" : "gray",
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Landing Services
            </Typography>
          </Box>

          <Box
            onClick={() => setShowServices(false)}
            sx={{
              width: "100%",
              borderTop: showServices ? "3px solid gray" : "3px solid #27bac4",
              borderRadius: "0px",
              textAlign: "left",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              padding: "3px",
              transition: "ease-in",
              transitionDuration: "250ms",
            }}
          >
            <Typography
              sx={{
                pt: 0,
                color: showServices ? "gray" : "#27bac4",
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Other Chargeable Services
            </Typography>
          </Box>
        </Box>

        {showServices ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px 0px 20px 0px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate("/Admin/add-service")}
              >
                Add
              </Button>
            </Box>
            <TableContainer sx={{ borderRadius: "8px" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h9_1" color="#333333">
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ borderTop: ".5px solid #737373" }}
                    >
                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.title || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                       {stripHtml(item?.description || "-").slice(0, 20)}...
                       {/* {item?.description || "-"} */}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                        S$  {item?.amount || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/edit-service/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <EditIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px 0px 20px 0px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate("/Admin/add-other-service")}
              >
                Add
              </Button>
            </Box>

            <TableContainer sx={{ borderRadius: "8px" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h9_1" color="#333333">
                        Title
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="h9_1" color="#333333">
                        Description
                      </Typography>
                    </TableCell>

                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {otherService.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ borderTop: ".5px solid #737373" }}
                    >
                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.title || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                        {stripHtml(item?.description || "-").slice(0, 20)}...
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                        S$  {item?.amount || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/edit-other-service/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <EditIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </>
  );
}
