import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  InputLabel,
  Stack,
  Card,
  CardContent,
} from "@mui/material";
import { ReactComponent as UploadFileIcon } from "../../../Icons/UploadFileIcon.svg";
import { ReactComponent as ImgIcon } from "../../../Icons/ImgIcon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { ReactComponent as FormIcon } from "../../../Icons/CommonFormIcon.svg";
import CloseIcon from '@mui/icons-material/Close';

export default function HostelAdd() {
  const reactQuillRef = useRef();
  const navigate = useNavigate();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"], // superscript/subscript
    ],
  };

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    register,
    watch,
  } = useForm({
    mode: "onTouched",
  });

  const des = watch("serviceFacility_Image_1");

  const Imgwatch = watch("img");
  const descriptionWatch = watch("description");

  const imgFile = useRef();
  const [Newsdata, setNewsdata] = useState({
    title: "",
    description: "",
    img: "",
  });
  const [bgImg, setBgImg] = useState([]);

  const initialHostelTypes = {
    Hostel_type: "",
    room_type: [
      { room_type_name: "", six_month_cont: "", twelve_month_cont: "", rooms : [
        {
          id : '',
          roomName : '',
          totalSlots : '',
          availableSlots : '',
          members : []
        },
      ]},
    ],
  };

  const [HostelTypes, setHostelTypes] = useState([initialHostelTypes]);

  console.log(HostelTypes, 'HostelTypes buddy')

  const initialServiceFacility = {
    img: "",
    title: "",
    icon: "",
    Description: "",
  };
  const [serviceFacility, setServicesFacility] = useState([
    initialServiceFacility,
  ]);

  const [ViewsData, setViewsData] = useState([{ img: "", title: "" }]);

  useEffect(() => {
    if (Boolean(Imgwatch)) {
      setBgImg((prev) => []);

      Array.from(Imgwatch)?.map((item, index) =>
        setBgImg((prev) => [...prev, { id: index + 1, img: item }])
      );
    }
  }, [Imgwatch]);

  // const onSubmit = async (data) => {
  //   const formData = new FormData();
  //   console.log(data, "data");
  //   formData.append("title", data?.title);
  //   formData.append("description", data?.description);
  //   formData.append("location", data?.location);

  //   bgImg.forEach((item, index) => {
  //     formData.append(`bgImg[${index}][id]`, item?.id);
  //     formData.append(`bgImg[${index}][img]`, item?.img);
  //   });

  //   HostelTypes.forEach((item, index) => {
  //     formData.append(`HostelTypes[${index}][id]`, index + 1);
  //     formData.append(
  //       `HostelTypes[${index}][Hostel_type]`,
  //       data[`Hostel_type${index + 1}`]
  //     );

  //     item?.room_type.forEach((itm, ind) => {
  //       formData.append(
  //         `HostelTypes[${index}][room_type][${ind}][id]`,
  //         ind + 1
  //       );
  //       formData.append(
  //         `HostelTypes[${index}][room_type][${ind}][room_type_name]`,
  //         data[`room_type_${index + 1}_${ind + 1}`]
  //       );
  //       formData.append(
  //         `HostelTypes[${index}][room_type][${ind}][six_month_cont]`,
  //         data[`six_month_cont_${index + 1}_${ind + 1}`]
  //       );
  //       formData.append(
  //         `HostelTypes[${index}][room_type][${ind}][twelve_month_cont]`,
  //         data[`twelve_month_cont_${index + 1}_${ind + 1}`]
  //       );
  //     });
  //   });

  //   serviceFacility.forEach((item, index) => {
  //     formData.append(`serviceFacility[${index}][id]`, index + 1);
  //     formData.append(
  //       `serviceFacility[${index}][img]`,
  //       data[`serviceFacility_Image_${index + 1}`]
  //     );
  //     formData.append(
  //       `serviceFacility[${index}][title]`,
  //       data[`serviceFacility_Title_${index + 1}`]
  //     );
  //     formData.append(
  //       `serviceFacility[${index}][icon]`,
  //       data[`serviceFacility_Icon_${index + 1}`]
  //     );
  //     formData.append(
  //       `serviceFacility[${index}][Description]`,
  //       data[`serviceFacility_des_${index + 1}`]
  //     );
  //   });

  //   ViewsData.forEach((item, index) => {
  //     formData.append(`Views[${index}][id]`, index + 1);
  //     formData.append(`Views[${index}][img]`, data[`Views_Image_${index + 1}`]);
  //     formData.append(
  //       `Views[${index}][title]`,
  //       data[`Views_Title_${index + 1}`]
  //     );
  //   });

  //   try {
  //     console.log(formData, "formData");
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}addhostel`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     navigate("/Admin/Hostellist");
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const onSubmit = async (data) => {
    const formData = new FormData();
    console.log(data, "data");
    formData.append("title", data?.title);
    formData.append("description", data?.description);
    formData.append("location", data?.location);
  
    bgImg.forEach((item, index) => {
      formData.append(`bgImg[${index}][id]`, item?.id);
      formData.append(`bgImg[${index}][img]`, item?.img);
    });
  
    HostelTypes.forEach((item, index) => {
      formData.append(`HostelTypes[${index}][id]`, index + 1);
      formData.append(
        `HostelTypes[${index}][Hostel_type]`,
        data[`Hostel_type${index + 1}`]
      );
  
      item?.room_type.forEach((roomItem, roomIndex) => {
        formData.append(
          `HostelTypes[${index}][room_type][${roomIndex}][id]`,
          roomIndex + 1
        );
        formData.append(
          `HostelTypes[${index}][room_type][${roomIndex}][room_type_name]`,
          data[`room_type_${index + 1}_${roomIndex + 1}`]
        );
        formData.append(
          `HostelTypes[${index}][room_type][${roomIndex}][six_month_cont]`,
          data[`six_month_cont_${index + 1}_${roomIndex + 1}`]
        );
        formData.append(
          `HostelTypes[${index}][room_type][${roomIndex}][twelve_month_cont]`,
          data[`twelve_month_cont_${index + 1}_${roomIndex + 1}`]
        );
  
        roomItem?.rooms.forEach((room, roomInd) => {
          formData.append(
            `HostelTypes[${index}][room_type][${roomIndex}][rooms][${roomInd}][id]`,
            roomInd + 1
          );
          formData.append(
            `HostelTypes[${index}][room_type][${roomIndex}][rooms][${roomInd}][roomName]`,
            data[`room_name_${index + 1}_${roomIndex + 1}_${roomInd + 1}`]
          );
          formData.append(
            `HostelTypes[${index}][room_type][${roomIndex}][rooms][${roomInd}][totalSlots]`,
            data[`room_slots_${index + 1}_${roomIndex + 1}_${roomInd + 1}`]
          );
          formData.append(
            `HostelTypes[${index}][room_type][${roomIndex}][rooms][${roomInd}][availableSlots]`,
            data[`room_slots_${index + 1}_${roomIndex + 1}_${roomInd + 1}`]
          );
          formData.append(
            `HostelTypes[${index}][room_type][${roomIndex}][rooms][${roomInd}][members]`,
            []
          );
          // room.members.forEach((member, memInd) => {
          //   formData.append(
          //     `HostelTypes[${index}][room_type][${roomIndex}][rooms][${roomInd}][members][${memInd}]`,
          //     member || ''
          //   );
          // });
        });
        
      });
    });


      serviceFacility.forEach((item, index) => {
      formData.append(`serviceFacility[${index}][id]`, index + 1);
      formData.append(
        `serviceFacility[${index}][img]`,
        data[`serviceFacility_Image_${index + 1}`]
      );
      formData.append(
        `serviceFacility[${index}][title]`,
        data[`serviceFacility_Title_${index + 1}`]
      );
      formData.append(
        `serviceFacility[${index}][icon]`,
        data[`serviceFacility_Icon_${index + 1}`]
      );
      formData.append(
        `serviceFacility[${index}][Description]`,
        data[`serviceFacility_des_${index + 1}`]
      );
    });

    ViewsData.forEach((item, index) => {
      formData.append(`Views[${index}][id]`, index + 1);
      formData.append(`Views[${index}][img]`, data[`Views_Image_${index + 1}`]);
      formData.append(
        `Views[${index}][title]`,
        data[`Views_Title_${index + 1}`]
      );
    });

      try {
      console.log(formData, "formData");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}add-hostel-test`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navigate("/Admin/Hostellist");
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  

  const handleAddRoomType = (ind) => {
    setHostelTypes((prev) => {
      const data = HostelTypes.map((itm, index) => {
        if (index === ind) {
          return {
            ...itm,
            room_type: [
              ...itm["room_type"],
              { room_type_name: "", six_month_cont: "", twelve_month_cont: "", rooms : [] },
            ],
          };
        }
        return itm;
      });
      return data;
    });
  };

  
  const handleAddRoom = (hostelIndex, roomTypeIndex) => {
    setHostelTypes((prev) => {
      const updatedHostelTypes = prev.map((hostel, index) => {
        if (index === hostelIndex) {
          const updatedRoomTypes = hostel.room_type.map((roomType, rIndex) => {
            if (rIndex === roomTypeIndex) {
              return {
                ...roomType,
                rooms: [
                  ...roomType.rooms,
                  {
                    id: roomType.rooms.length + 1,
                    roomName : '',
                    totalSlots: '',
                    availableSlots: '',
                    members: []
                  }
                ]
              };
            }
            return roomType;
          });
          return { ...hostel, room_type: updatedRoomTypes };
        }
        return hostel;
      });
      return updatedHostelTypes;
    });
  };
  

  const handleDeleteRoom = (hostelIndex, roomTypeIndex, roomIndex) => {
    setHostelTypes((prev) => {
      const updatedHostelTypes = prev.map((hostel, index) => {
        if (index === hostelIndex) {
          const updatedRoomTypes = hostel.room_type.map((roomType, rIndex) => {
            if (rIndex === roomTypeIndex) {
              const updatedRooms = roomType.rooms.filter(
                (room, rIndex) => rIndex !== roomIndex
              );
              return { ...roomType, rooms: updatedRooms };
            }
            return roomType;
          });
          return { ...hostel, room_type: updatedRoomTypes };
        }
        return hostel;
      });
      return updatedHostelTypes;
    });
  };
  


  const handleDeleteHostelType = (ind) => {
    const data = HostelTypes.filter((itm, index) => ind !== index);
    setHostelTypes(data);
  };

  const handleDeleteRoomType = (ind, subind) => {
    setHostelTypes((prev) => {
      const data = HostelTypes.map((itm, index) => {
        if (index === ind) {
          const datas = itm?.room_type?.filter(
            (subitm, subindex) => subind !== subindex
          );
          return { ...itm, room_type: datas };
        }
        return itm;
      });
      return data;
    });
  };

  const handleDeleteServicesFacility = (ind) => {
    const data = serviceFacility.filter((itm, index) => ind !== index);
    setServicesFacility(data);
  };

  const handleDeleteViews = (ind) => {
    const data = ViewsData.filter((itm, index) => ind !== index);
    setViewsData(data);
  };

  const deleteFirstImage = (id) => {
    let updatedFiles = Array.from(Imgwatch);
    updatedFiles = updatedFiles.filter((file, index) => index !== id);
    setValue("img", updatedFiles);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            width: "80%",
            border: "1px solid #E5E7EB",
            borderRadius: "6px",
          }}
        >
          <CardContent style={{ padding: 0, margin: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#FCFCFD",
                padding: 2,
                border: "1px solid #E5E7EB",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <FormIcon style={{ marginRight: "10px" }} />
              <Typography variant="h6_1">Hostel Details</Typography>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box style={{ padding: "25px 17px 17px 17px" }}>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {errors?.title ? "Title*" : "Title"}
                </InputLabel>
                <Controller
                  name="title"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors?.title) ? true : false}
                      placeholder="Enter title"
                      sx={{
                        mt: 0,
                        backgroundColor: "rgb(249, 250, 251)",
                        borderRadius: 0,
                        "& .MuiInputBase-input": {
                          color: errors?.title ? "red" : "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: errors?.title
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&:hover fieldset": {
                            borderColor: errors?.title
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: errors?.title
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                        },
                      }}
                    />
                  )}
                />
                {errors?.title ? (
                  <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                    Please enter title
                  </Typography>
                ) : null}
              </Box>

              <Box style={{ padding: "0px 17px 17px" }}>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {errors?.location ? "Location*" : "Location"}
                </InputLabel>
                <Controller
                  name="location"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder="Enter location"
                      error={Boolean(errors?.location) ? true : false}
                      {...field}
                      sx={{
                        mt: 0,
                        backgroundColor: "rgb(249, 250, 251)",
                        borderRadius: 0,
                        "& .MuiInputBase-input": {
                          color: errors?.location ? "red" : "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: errors?.location
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&:hover fieldset": {
                            borderColor: errors?.location
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: errors?.location
                              ? "#ff5e74"
                              : "rgba(0, 0, 0, 0.23)",
                          },
                        },
                      }}
                    />
                  )}
                />
                {errors?.location ? (
                  <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                    Please enter location
                  </Typography>
                ) : null}
              </Box>

              <Box style={{ padding: "0px 17px 17px" }}>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {errors?.description ? "Description*" : "Description"}
                </InputLabel>
                <Controller
                  name="description"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <ReactQuill
                      style={{ height: "180px" }}
                      modules={modules}
                      maxLength={3000}
                      {...field}
                    />
                  )}
                />
              </Box>

              <Box style={{ marginTop: "2rem", marginLeft: "1rem" }}>
                {errors?.description ? (
                  <Typography style={{ color: "#ff5e74", margin: "0.3rem" }}>
                    Please enter description
                  </Typography>
                ) : null}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "#FCFCFD",
                  padding: 2,
                  border: "1px solid #E5E7EB",
                  marginTop: 7,
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Hostel Image
                </Typography>
              </Box>

              {Boolean(Imgwatch?.length > 0) ? (
                Array.from(Imgwatch)?.map((item, index) => (
                  <Box style={{ padding: "0px 17px 17px" }}>
                    <Stack
                      mt={2}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      padding="23px"
                      borderRadius="8px"
                      sx={{ border: ".5px solid #1A2461" }}
                    >
                      <Typography
                        variant="h9_1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <ImgIcon />
                        {item?.name}
                      </Typography>
                      <ClearIcon
                        onClick={() => deleteFirstImage(index)}
                        sx={{ color: "#1A2461" }}
                      />
                    </Stack>
                  </Box>
                ))
              ) : (
                <Box
                  xs={12}
                  onClick={() => imgFile.current.click()}
                  sx={{
                    border: Boolean(errors.img)
                      ? "1px dashed red"
                      : "1px dashed #1A2461",
                    height: "200px",
                    borderRadius: "8px",
                    backgroundColor: "#F9FBFF",
                    margin: "20px 17px 17px",
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "100px",
                        backgroundColor: "#F2F2F2",
                      }}
                    >
                      <UploadFileIcon fill="#1A2461" />
                    </Stack>
                    <Stack mt={2} direction="row" alignItems="center" gap="5px">
                      <Typography variant="h9_1">Click to upload</Typography>
                    </Stack>
                    <Typography variant="h9_5">
                      Allowed formats - JPEG, PNG
                    </Typography>
                  </Stack>
                  <Box sx={{ marginTop: "0.3rem" }}>
                    {errors?.img ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please select an Image
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              )}
              <Controller
                name="img"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                    ref={(e) => {
                      imgFile.current = e;
                    }}
                    hidden
                    multiple
                  />
                )}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "#FCFCFD",
                  padding: 2,
                  border: "1px solid #E5E7EB",
                  marginTop: 7,
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Pricing
                </Typography>
              </Box>

              {HostelTypes?.map((itm, ind) => (
                <>
                  <Box style={{ padding: "25px 17px 17px 17px" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <InputLabel
                        sx={{
                          mb: 1,
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "black",
                        }}
                      >
                        {Boolean(errors[`Hostel_type${ind + 1}`])
                          ? "Hostel Type*"
                          : "Hostel Type"}
                      </InputLabel>

                      {ind > 0 && (
                        <IconButton onClick={() => handleDeleteHostelType(ind)}>
                          {" "}
                          <ClearIcon
                            sx={{ color: "#f37777", fontSize: "30px" }}
                          />
                        </IconButton>
                      )}
                    </Stack>

                    <Controller
                      name={`Hostel_type${ind + 1}`}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter hostel type"
                          error={
                            Boolean(errors[`Hostel_type${ind + 1}`])
                              ? true
                              : false
                          }
                          {...field}
                          sx={{
                            mt: 0,
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: Boolean(errors[`Hostel_type${ind + 1}`])
                                ? "red"
                                : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: Boolean(
                                  errors[`Hostel_type${ind + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: Boolean(
                                  errors[`Hostel_type${ind + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: Boolean(
                                  errors[`Hostel_type${ind + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {Boolean(errors[`Hostel_type${ind + 1}`]) ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter hostel type
                      </Typography>
                    ) : null}

                    {itm?.room_type?.map((item, index) => (
                      <>
                        <Card
                          sx={{
                            width: "100%",
                            border: "1px solid #E5E7EB",
                            borderRadius: "6px",
                            margin: "15px 0px",
                            boxShadow: "0px 1px 10px -7px rgba(0,0,0,0.75)",
                          }}
                        >
                          <CardContent>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              style={{ marginTop: "20px" }}
                            >
                              <InputLabel
                                sx={{
                                  mb: 1,
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  color: "black",
                                }}
                              >
                                {Boolean(
                                  errors[`room_type_${ind + 1}_${index + 1}`]
                                )
                                  ? "Room Type*"
                                  : "Room Type"}
                              </InputLabel>

                              {index > 0 && (
                                <IconButton
                                  onClick={() =>
                                    handleDeleteRoomType(ind, index)
                                  }
                                >
                                  {" "}
                                  <ClearIcon
                                    sx={{ color: "#f37777", fontSize: "30px" }}
                                  />
                                </IconButton>
                              )}
                            </Stack>
                            <Controller
                              name={`room_type_${ind + 1}_${index + 1}`}
                              rules={{ required: true }}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  placeholder="Enter room type"
                                  error={
                                    Boolean(
                                      errors[
                                        `room_type_${ind + 1}_${index + 1}`
                                      ]
                                    )
                                      ? true
                                      : false
                                  }
                                  {...field}
                                  sx={{
                                    mt: 0,
                                    backgroundColor: "rgb(249, 250, 251)",
                                    borderRadius: 0,
                                    "& .MuiInputBase-input": {
                                      color: Boolean(
                                        errors[
                                          `room_type_${ind + 1}_${index + 1}`
                                        ]
                                      )
                                        ? "red"
                                        : "black",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: Boolean(
                                          errors[
                                            `room_type_${ind + 1}_${index + 1}`
                                          ]
                                        )
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: Boolean(
                                          errors[
                                            `room_type_${ind + 1}_${index + 1}`
                                          ]
                                        )
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: Boolean(
                                          errors[
                                            `room_type_${ind + 1}_${index + 1}`
                                          ]
                                        )
                                          ? "#ff5e74"
                                          : "rgba(0, 0, 0, 0.23)",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                            {Boolean(
                              errors[`room_type_${ind + 1}_${index + 1}`]
                            ) ? (
                              <Typography
                                style={{ color: "#ff5e74", margin: "0.3rem" }}
                              >
                                Please enter room type
                              </Typography>
                            ) : null}

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignContent: "center",
                              }}
                              style={{ gap: "10px" }}
                            >
                              <Box style={{ width: "100%" }}>
                                <InputLabel
                                  sx={{
                                    mt: "20px",
                                    mb: 1,
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    color: "black",
                                  }}
                                >
                                  {Boolean(
                                    errors[
                                      `six_month_cont_${ind + 1}_${index + 1}`
                                    ]
                                  )
                                    ? "6 month contract*"
                                    : "6 month contract"}
                                </InputLabel>

                                <Controller
                                  name={`six_month_cont_${ind + 1}_${index + 1}`}
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                    inputProps={{
                                      inputMode: 'numeric',
                                      pattern: '[0-9]*',
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                      type="text"
                                      placeholder="Enter amount"
                                      error={
                                        Boolean(
                                          errors[
                                            `six_month_cont_${ind + 1}_${
                                              index + 1
                                            }`
                                          ]
                                        )
                                          ? true
                                          : false
                                      }
                                      {...field}
                                      sx={{
                                        mt: 0,
                                        backgroundColor: "rgb(249, 250, 251)",
                                        borderRadius: 0,
                                        "& .MuiInputBase-input": {
                                          color: Boolean(
                                            errors[
                                              `six_month_cont_${ind + 1}_${
                                                index + 1
                                              }`
                                            ]
                                          )
                                            ? "red"
                                            : "black",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `six_month_cont_${ind + 1}_${
                                                  index + 1
                                                }`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `six_month_cont_${ind + 1}_${
                                                  index + 1
                                                }`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `six_month_cont_${ind + 1}_${
                                                  index + 1
                                                }`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                                {Boolean(
                                  errors[
                                    `six_month_cont_${ind + 1}_${index + 1}`
                                  ]
                                ) ? (
                                  <Typography
                                    style={{
                                      color: "#ff5e74",
                                      margin: "0.3rem",
                                    }}
                                  >
                                    Please enter 6 month contract amount
                                  </Typography>
                                ) : null}
                              </Box>

                              <Box sx={{ width: "100%" }}>
                                <InputLabel
                                  sx={{
                                    mt: "20px",
                                    mb: 1,
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    color: "black",
                                  }}
                                >
                                  {Boolean(
                                    errors[
                                      `twelve_month_cont_${ind + 1}_${
                                        index + 1
                                      }`
                                    ]
                                  )
                                    ? "12 month contract*"
                                    : "12 month contract"}
                                </InputLabel>

                                <Controller
                                  name={`twelve_month_cont_${ind + 1}_${
                                    index + 1
                                  }`}
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                    inputProps={{
                                      inputMode: 'numeric',
                                      pattern: '[0-9]*',
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                      type="text"
                                      placeholder="Enter amount"
                                      error={
                                        Boolean(
                                          errors[
                                            `twelve_month_cont_${ind + 1}_${
                                              index + 1
                                            }`
                                          ]
                                        )
                                          ? true
                                          : false
                                      }
                                      {...field}
                                      sx={{
                                        mt: 0,
                                        backgroundColor: "rgb(249, 250, 251)",
                                        borderRadius: 0,
                                        "& .MuiInputBase-input": {
                                          color: Boolean(
                                            errors[
                                              `twelve_month_cont_${ind + 1}_${
                                                index + 1
                                              }`
                                            ]
                                          )
                                            ? "red"
                                            : "black",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `twelve_month_cont_${ind + 1}_${
                                                  index + 1
                                                }`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `twelve_month_cont_${ind + 1}_${
                                                  index + 1
                                                }`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `twelve_month_cont_${ind + 1}_${
                                                  index + 1
                                                }`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                                {Boolean(
                                  errors[
                                    `twelve_month_cont_${ind + 1}_${index + 1}`
                                  ]
                                ) ? (
                                  <Typography
                                    style={{
                                      color: "#ff5e74",
                                      margin: "0.3rem",
                                    }}
                                  >
                                    Please enter 12 month contract amount
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            
                            {item.rooms.map((room, roomIndex) => (
                              <Card sx={{mt : 2, background : 'linear-gradient(30deg, rgba(243,243,243,1) 0%, rgba(248,248,248,1) 40%, rgba(253,253,253,1) 100%)'}}>
                              <CardContent>
                              <Box sx={{justifyContent : 'flex-end', display : roomIndex === 0 ? 'none' : 'flex'}}>
                                <Button onClick={() => handleDeleteRoom(ind, index, roomIndex)}><CloseIcon /></Button>
                                </Box>
                            <Box key={roomIndex} sx={{ display : 'flex', justifyContent : 'space-between', mb : 2 }}>
                              {/* Room Name Controller */}
                              <Box sx={{width : '49%'}}>
                              <InputLabel
                                  sx={{
                                    mt: "20px",
                                    mb: 1,
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    color: "black",
                                  }}
                                >
                                  {Boolean(
                                    errors[
                                      `room_name_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                    ]
                                  )
                                    ? "Room name*"
                                    : "Room name"}
                                </InputLabel>
                              <Controller
                                name={`room_name_${ind + 1}_${index + 1}_${roomIndex + 1}`}
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    placeholder="Enter room name"
                                    {...field}
                                    sx={{
                                      mt: 0,
                                      backgroundColor: "rgb(249, 250, 251)",
                                      borderRadius: 0,
                                      "& .MuiInputBase-input": {
                                        color: Boolean(
                                          errors[
                                            `room_name_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                          ]
                                        )
                                          ? "red"
                                          : "black",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `room_name_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `room_name_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: Boolean(
                                            errors[
                                              `room_name_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                            ]
                                          )
                                            ? "#ff5e74"
                                            : "rgba(0, 0, 0, 0.23)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                              {Boolean(
                                  errors[
                                    `room_name_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                  ]
                                ) ? (
                                  <Typography
                                    style={{
                                      color: "#ff5e74",
                                      margin: "0.3rem",
                                    }}
                                  >
                                    Please enter room name
                                  </Typography>
                                ) : null}
                                </Box>

                                  <Box sx={{width : '49%'}}>
                                  <InputLabel
                                  sx={{
                                    mt: "20px",
                                    mb: 1,
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    color: "black",
                                  }}
                                >
                                  {Boolean(
                                    errors[
                                      `room_slots_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                    ]
                                  )
                                    ? "Room slots*"
                                    : "Room slots"}
                                </InputLabel>
                                <Controller
                                  name={`room_slots_${ind + 1}_${index + 1}_${roomIndex + 1}`}
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                      }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                      }}
                                      type="text"
                                      placeholder="Enter total slots"
                                      {...field}
                                      sx={{
                                        mt: 0,
                                        backgroundColor: "rgb(249, 250, 251)",
                                        borderRadius: 0,
                                        "& .MuiInputBase-input": {
                                          color: Boolean(
                                            errors[
                                              `room_slots_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                            ]
                                          )
                                            ? "red"
                                            : "black",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `room_slots_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `room_slots_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: Boolean(
                                              errors[
                                                `room_slots_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                              ]
                                            )
                                              ? "#ff5e74"
                                              : "rgba(0, 0, 0, 0.23)",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />

                                {Boolean(
                                  errors[
                                    `room_slots_${ind + 1}_${index + 1}_${roomIndex + 1}`
                                  ]
                                ) ? (
                                  <Typography
                                    style={{
                                      color: "#ff5e74",
                                      margin: "0.3rem",
                                    }}
                                  >
                                    Please enter room slots
                                  </Typography>
                                ) : null}
                                </Box>
                              </Box>

                                
                              </CardContent>
                            </Card>
                            ))}
                           
                            <Box sx={{mt : 2, display : 'flex', justifyContent : 'flex-end'}}>
                            <Button variant='contained' onClick={() => handleAddRoom(ind, index)}>Add room</Button>
                            </Box>

                            
                            {index === itm.room_type.length - 1 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  mt: "40px",
                                }}
                              >
                                <Button
                                  style={{
                                    padding: "6px 0px",
                                    width: "25%",
                                    fontSize: "1rem",
                                  }}
                                  onClick={() => {
                                    handleAddRoomType(ind);
                                  }}
                                  variant="outlined"
                                >
                                  Add more Room type
                                </Button>
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </>
                    ))}
                  </Box>
                </>
              ))}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0px 17px",
                }}
              >
                <Button
                style={{
                  padding: "6px 0px",
                  width: "22%",
                  fontSize: "1rem",
                }}
                  onClick={() => {
                    setHostelTypes((prev) => [...prev, initialHostelTypes]);
                  }}
                  variant="contained"
                >
                  Add more hostel type
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "#FCFCFD",
                  padding: 2,
                  border: "1px solid #E5E7EB",
                  marginTop: 7,
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Services & Facilities
                </Typography>
              </Box>

              {serviceFacility?.map((item, index) => (
                <>
                  <Box style={{ padding: "0px 17px 17px 17px" }}>
                    <Stack
                      mt={5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <InputLabel
                        sx={{
                          mt: "20px",
                          mb: 1,
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "black",
                        }}
                      >
                        {Boolean(errors[`serviceFacility_Title_${index + 1}`])
                          ? "Title*"
                          : "Title"}
                      </InputLabel>

                      {index > 0 && (
                        <IconButton
                          onClick={() => handleDeleteServicesFacility(index)}
                        >
                          {" "}
                          <ClearIcon
                            sx={{ color: "#f37777", fontSize: "30px" }}
                          />
                        </IconButton>
                      )}
                    </Stack>
                    <Controller
                      name={`serviceFacility_Title_${index + 1}`}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter title"
                          error={
                            Boolean(
                              errors[`serviceFacility_Title_${index + 1}`]
                            )
                              ? true
                              : false
                          }
                          {...field}
                          sx={{
                            mt: 0,
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: Boolean(
                                errors[`serviceFacility_Title_${index + 1}`]
                              )
                                ? "red"
                                : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: Boolean(
                                  errors[`serviceFacility_Title_${index + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: Boolean(
                                  errors[`serviceFacility_Title_${index + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: Boolean(
                                  errors[`serviceFacility_Title_${index + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />

                    {Boolean(errors[`serviceFacility_Title_${index + 1}`]) ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter title
                      </Typography>
                    ) : null}

                    <Box sx={{ paddingBottom: "30px" }}>
                      <InputLabel
                        sx={{
                          mt: "20px",
                          mb: 1,
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "black",
                        }}
                      >
                        {Boolean(errors[`serviceFacility_des_${index + 1}`])
                          ? "Description*"
                          : "Description"}
                      </InputLabel>
                      <Controller
                        name={`serviceFacility_des_${index + 1}`}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <ReactQuill
                            style={{ height: "180px" }}
                            modules={modules}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Box style={{ marginTop: "1rem" }}>
                      {Boolean(errors[`serviceFacility_des_${index + 1}`]) ? (
                        <Typography
                          style={{ color: "#ff5e74", margin: "0.3rem" }}
                        >
                          Please enter description
                        </Typography>
                      ) : null}
                    </Box>

                    <InputLabel
                      sx={{
                        mt: "20px",
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {Boolean(errors[`serviceFacility_Image_${index + 1}`])
                        ? "Image*"
                        : "Image"}
                    </InputLabel>
                    <Controller
                      name={`serviceFacility_Image_${index + 1}`}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            field.onChange(e.target.files[0]);
                          }}
                        />
                      )}
                    />
                    {Boolean(errors[`serviceFacility_Image_${index + 1}`]) ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please select an image
                      </Typography>
                    ) : null}

                    <InputLabel
                      sx={{
                        mt: "20px",
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {Boolean(errors[`serviceFacility_Icon_${index + 1}`])
                        ? "Icon*"
                        : "Icon"}
                    </InputLabel>
                    <Controller
                      name={`serviceFacility_Icon_${index + 1}`}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="file"
                          accept="image/*"
                          onChange={(e) => field.onChange(e.target.files[0])}
                        />
                      )}
                    />
                    {Boolean(errors[`serviceFacility_Icon_${index + 1}`]) ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please select an icon
                      </Typography>
                    ) : null}
                  </Box>
                </>
              ))}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 17px",
                }}
              >
                <Button
                style={{
                  padding: "6px 0px",
                  width: "25%",
                  fontSize: "1rem",
                }}
                  onClick={() => {
                    setServicesFacility((prev) => [
                      ...prev,
                      initialServiceFacility,
                    ]);
                  }}
                  variant="contained"
                >
                  Add more Servies & Facility
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "#FCFCFD",
                  padding: 2,
                  border: "1px solid #E5E7EB",
                  marginTop: 7,
                }}
              >
                <FormIcon style={{ marginRight: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Views
                </Typography>
              </Box>

              {ViewsData?.map((item, index) => (
                <>
                  <Box style={{ padding: "0px 17px 17px 17px" }}>
                    <Stack
                      mt={5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <InputLabel
                        sx={{
                          mt: "0px",
                          mb: 1,
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "black",
                        }}
                      >
                        {Boolean(errors[`Views_Title_${index + 1}`])
                          ? "Title*"
                          : "Title"}
                      </InputLabel>
                      {index > 0 && (
                        <IconButton onClick={() => handleDeleteViews(index)}>
                          {" "}
                          <ClearIcon
                            sx={{ color: "#f37777", fontSize: "30px" }}
                          />
                        </IconButton>
                      )}
                    </Stack>
                    <Controller
                      name={`Views_Title_${index + 1}`}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter title"
                          error={
                            Boolean(errors[`Views_Title_${index + 1}`])
                              ? true
                              : false
                          }
                          {...field}
                          sx={{
                            mt: 0,
                            backgroundColor: "rgb(249, 250, 251)",
                            borderRadius: 0,
                            "& .MuiInputBase-input": {
                              color: Boolean(errors[`Views_Title_${index + 1}`])
                                ? "red"
                                : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: Boolean(
                                  errors[`Views_Title_${index + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&:hover fieldset": {
                                borderColor: Boolean(
                                  errors[`Views_Title_${index + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: Boolean(
                                  errors[`Views_Title_${index + 1}`]
                                )
                                  ? "#ff5e74"
                                  : "rgba(0, 0, 0, 0.23)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {Boolean(errors[`Views_Title_${index + 1}`]) ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please enter title
                      </Typography>
                    ) : null}

                    <InputLabel
                      sx={{
                        mt: "10px",
                        mb: 1,
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      {Boolean(errors[`Views_Image_${index + 1}`])
                        ? "Image*"
                        : "Image"}
                    </InputLabel>
                    <Controller
                      name={`Views_Image_${index + 1}`}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            field.onChange(e.target.files[0]);
                          }}
                        />
                      )}
                    />
                    {Boolean(errors[`Views_Image_${index + 1}`]) ? (
                      <Typography
                        style={{ color: "#ff5e74", margin: "0.3rem" }}
                      >
                        Please select an image
                      </Typography>
                    ) : null}
                  </Box>
                </>
              ))}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 17px",
                }}
              >
                <Button
                style={{
                  padding: "6px 0px",
                  width: "25%",
                  fontSize: "1rem",
                }}
                  onClick={() => {
                    setViewsData((prev) => [...prev, { img: "", title: "" }]);
                  }}
                  variant="contained"
                >
                  Add more Views
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "15px 17px",
                  border: "1px solid #E5E7EB",
                  margin: "30px 0px 0px 0px",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgb(14, 94, 132)",
                    fontWeight: 700,
                    width: "15%",
                    padding: "15px 0px",
                    fontSize: "1.1rem",
                  }}
                  type="submit"
                >
                  Confirm
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
