import React, { useState } from "react";
import {
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  InputAdornment,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as MailIcon } from "../Icons/MailIcon.svg";
import { ReactComponent as PasswordIcon } from "../Icons/PasswordIcon.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { emailRegex } from "../Utils";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeData } from "../Redux/Feature/AdminSlice";

export default function Login({ setDisplayScreen }) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onTouched",
  });
  const dispatch = useDispatch()
  const [passowordEye, setPassowordEye] = useState(true);
  const [responseError, setResponseError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
 
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}login`,
        data
      );
      if (response.data?.isvaild) {
        localStorage.setItem("token", response.data?.token);
        if(response.data.isAdmin === true){
          setTimeout(() => {
            navigate("/Admin");
            setLoading(false);
          }, 1000);
        }else {
          navigate('/')
        }
       
        dispatch(storeData(response.data))
      }
    } catch (error) {
      setResponseError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  
  return (
    <Grid
      xs={12}
      sm={8}
      md={5}
      lg={3.5}
      p={6}
      sx={{
        border: ".5px solid rgba(26, 36, 97, 1)",
        borderRadius: "20px",
        height: "auto",
        boxShadow: '0px 5px 11px 0px rgba(0, 0, 0, 0.06)'
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Typography mb={0.5} variant="h4_1">
            Login{" "}
          </Typography>
          <Typography variant="h9_4">Login to your account</Typography>
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email is Required",
              validate: (value) =>
                emailRegex.test(value) == true || "Invalid Email",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors?.email) ? true : false}
                sx={{ mt: 4, "& .MuiInputBase-root": { color: "#003893" } }}
                placeholder="Email"
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {Boolean(errors?.email?.message) && (
            <Typography sx={{ color: "red", my: 1 }} variant="h9_4">
              {errors?.email?.message}
            </Typography>
          )}

          <Controller
            control={control}
            name="password"
            rules={{
              required: "Password is Required",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors?.password) ? true : false}
                sx={{ mt: 2, "& .MuiInputBase-root": { color: "#003893" } }}
                placeholder="Password"
                type={passowordEye ? "password" : "text"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      onClick={() => setPassowordEye(!passowordEye)}
                      position="end"
                    >
                      {passowordEye ? (
                        <VisibilityOffIcon sx={{ color: "#007ECA" }} />
                      ) : (
                        <RemoveRedEyeIcon sx={{ color: "#007ECA" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {Boolean(errors?.password?.message) && (
            <Typography sx={{ color: "red", my: 1 }} variant="h9_4">
              {errors?.password?.message}
            </Typography>
          )}
          {Boolean(responseError) && (
            <Typography sx={{ color: "red", my: 1 }} variant="h9_4">
              {responseError}
            </Typography>
          )}
          <Stack alignItems="end">
            <Typography
              mt={2}
              width="fit-content"
              variant="h9_1"
              color="#27BAC4"
              onClick={() => setDisplayScreen("forgotpassword")}
            >
              Forgot Password?
            </Typography>
          </Stack>
          <Button
            type="submit"
            sx={{
              mt: 3,
              width: "100%",
              backgroundColor: "#003893",
              "&:hover": { backgroundColor: "#003893" },
              fontSize: "16px",
              fontWeight: 700,
              color: "#FFFFFF",
              padding: "10px 20px",
              borderRadius: "8px",
              letterSpacing: ".7px",
            }}
          >
            {loading ? (
              <CircularProgress size={25} sx={{ color: "#FFFFFF" }} />
            ) : (
              "Login"
            )}
          </Button>
        </Stack>
      </form>
    </Grid>
  );
}
