import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import moment from "moment";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';



export default function Application() {
  const navigate = useNavigate();

  const [announcementData, setAnnouncementData] = useState([]);
  const [loading, setLoading] = useState(true)

  const getAllAnnouncement = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getAllApplication`
      );
      console.log(response?.data?.data);
      setAnnouncementData(response?.data?.data);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    getAllAnnouncement();
  }, []);

  const [value, setValue] = useState(() => {
    return localStorage.getItem('tabvalue') || '1'
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    localStorage.setItem('tabvalue', value)
  },[value])
 

  if(loading){
    return <p>Loading...</p>
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h7_1">Applications</Typography>
        {/* <Button onClick={() => navigate("AnnouncementAdd")} variant="contained">Add</Button> */}
      </Stack>
      <Divider sx={{ mb: 3, mt: 2 }} />
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Pending" value="1" />
            <Tab label="Unpaid - Room rent" value="2" />
            <Tab label="Paid - Room rent" value="3" />
            <Tab label="Rejected" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{pt : 4, px : 0}}>
        <TableContainer sx={{ borderRadius: "3px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{backgroundColor : '#efefef'}}>
              <TableCell>
                <Typography variant="h9_1" color="#333333">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Gender
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  HostelName
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Date & Time
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333"></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {announcementData.filter((item) => item?.applicationStatus === 'pending').length === 0 ? (
            <TableRow>
              <TableCell colSpan={5}>
            <Typography>No data found</Typography>
            </TableCell>
            </TableRow>
          ) : (
            announcementData
              .filter((item) => item?.applicationStatus === 'pending')
              .map((item, index) => (
              <TableRow
                key={item.name}
                sx={{ borderTop: ".5px solid #737373", cursor: "pointer" }}
                onClick={() => navigate(`/Admin/ApplicationView/${item?._id}`)}
              >
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.gender || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.RoomsData?.selectedRoom?.HostelName || "_"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {moment(item?.createdAt).format("MMM DD YYYY , hh:mm A") || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/Admin/ApplicationView/${item?._id}`)
                    }
                  >
                    Review
                  </Button>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
        </TabPanel>
        
        <TabPanel value="2" sx={{pt : 4, px : 0}}>
        <TableContainer sx={{ borderRadius: "3px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{backgroundColor : '#efefef'}}>
              <TableCell>
                <Typography variant="h9_1" color="#333333">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Gender
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  HostelName
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Date & Time
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333"></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {announcementData.filter((item) => item?.applicationStatus === 'success' && item?.signedStatus === 'unsigned').length === 0 ? (<>
          <TableRow>
            <TableCell colSpan={5}>
              <Typography>No data found</Typography>
            </TableCell>
          </TableRow>
          </>) : (
            announcementData.filter((item) => item?.applicationStatus === 'success' && item?.signedStatus === 'unsigned').map((item, index) => (
              <TableRow
                key={item.name}
                sx={{ borderTop: ".5px solid #737373", cursor: "pointer" }}
                onClick={() => navigate(`/Admin/ApplicationView/${item?._id}`)}
              >
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.gender || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.RoomsData?.selectedRoom?.HostelName || "_"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {moment(item?.createdAt).format("MMM DD YYYY , hh:mm A") || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/Admin/ApplicationView/${item?._id}`)
                    }
                  >
                    Review
                  </Button>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
        </TabPanel>
        
        
        <TabPanel value="3" sx={{pt : 4, px : 0}}>
        <TableContainer sx={{ borderRadius: "3px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{backgroundColor : '#efefef'}}>
              <TableCell>
                <Typography variant="h9_1" color="#333333">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Gender
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  HostelName
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Date & Time
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333"></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {announcementData.filter((item) => item?.applicationStatus === 'success' && item?.signedStatus === 'signed').length === 0 ? (<>
          <TableRow>
            <TableCell colSpan={5}>
              <Typography>No data found</Typography>
            </TableCell>
          </TableRow>
          </>) : (
            announcementData.filter((item) => item?.applicationStatus === 'success' && item?.signedStatus === 'signed').map((item, index) => (
              <TableRow
                key={item.name}
                sx={{ borderTop: ".5px solid #737373", cursor: "pointer" }}
                onClick={() => navigate(`/Admin/ApplicationView/${item?._id}`)}
              >
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.gender || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.RoomsData?.selectedRoom?.HostelName || "_"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {moment(item?.createdAt).format("MMM DD YYYY , hh:mm A") || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/Admin/ApplicationView/${item?._id}`)
                    }
                  >
                    view
                  </Button>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>     
        </TabPanel>
        
        <TabPanel value="4" sx={{pt : 4, px : 0}}>
        <TableContainer sx={{ borderRadius: "3px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{backgroundColor : '#efefef'}}>
              <TableCell>
                <Typography variant="h9_1" color="#333333">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Gender
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  HostelName
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Date & Time
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333"></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {announcementData.filter((item) => item?.applicationStatus === 'rejected').length === 0 ?
            (<>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography>No data found</Typography>
                </TableCell>
              </TableRow>
            </>) : (
            announcementData.filter((item) => item?.applicationStatus === 'rejected').map((item, index) => (
              <TableRow
                key={item.name}
                sx={{ borderTop: ".5px solid #737373", cursor: "pointer" }}
                onClick={() => navigate(`/Admin/ApplicationView/${item?._id}`)}
              >
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.name || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.PersonalData?.Details?.gender || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {item?.RoomsData?.selectedRoom?.HostelName || "_"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h9_4" color="#333333">
                    {moment(item?.createdAt).format("MMM DD YYYY , hh:mm A") || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/Admin/ApplicationView/${item?._id}`)
                    }
                  >
                    Review
                  </Button>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
        </TabPanel>
      </TabContext>
    </Box>
      
    </>
  );
}
