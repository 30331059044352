import { ReactComponent as LogoIcon } from "../../Icons/logo.svg";
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  Stack,
  MenuItem,
  Menu,
  ListItemIcon,
} from "@mui/material";
import { ReactComponent as NewsMenuIcon } from "../../Icons/Admin/NewsMenuIcon.svg";
import { ReactComponent as AnnouncementMenuIcon } from "../../Icons/Admin/AnnouncementMenuIcon.svg";
import { ReactComponent as HostelIcon } from "../../Icons/Admin/HostelIcon.svg";
import UserCircleIcon from "../../Icons/UserCircleIcon.svg";
import { ReactComponent as UserIcon } from "../../Icons/UserIcon.svg";
import { ReactComponent as LogoutIcon } from "../../Icons/LogoutIcon.svg";
import { ReactComponent as DownArrowIcon } from "../../Icons/DownArrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { removeData } from "../../Redux/Feature/AdminSlice";
import AssignmentIcon from '@mui/icons-material/Assignment';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ChatIcon from '@mui/icons-material/Chat';


export default function Dashboard() {
  const navigate = useNavigate();
  const admin = useSelector((state) => state.AdminData.admin)
  const dispatch = useDispatch()
  const [adminData, setAdmin] = useState({
    userName: admin?.userName || 'username',
  })
  const initialRenderBlock = localStorage.getItem('block')
    ? parseInt(localStorage.getItem('block'), 10)
    : 1;

  const [renderBlock, setRenderBlock] = useState(initialRenderBlock);
  const [anchorlEl, setanchorlEl] = useState(null);
  const open = Boolean(anchorlEl);
  const [currentPath, setCurrentPath] = useState(() => localStorage.getItem('currentPath') || '/Admin');

  const [navItems, setNavItems] = useState([
    {
      name: 'Announcement',
      path: '/Admin',
      icon: <AnnouncementMenuIcon />,
    },
    {
      name: 'News',
      path: '/Admin/NewsList',
      icon: <NewsMenuIcon />,
    },
    {
      name: 'Hostel',
      path: '/Admin/Hostellist',
      icon: <HostelIcon />,
    },
    {
      name: 'Application',
      path: '/Admin/Application',
      icon: <AssignmentIcon />,
    },
    {
      name: 'Services',
      path: '/Admin/Services',
      icon: <HailRoundedIcon />,
    },
    {
      name: 'Checklist',
      path: '/Admin/Checklist',
      icon: <ListAltIcon />,
    },
    {
      name: 'Charges',
      path: '/Admin/Charges',
      icon: <RequestQuoteIcon />,
    },
    {
      name : 'Queries',
      path : '/Admin/Queries',
      icon : <ChatIcon />
    }
  ])

  useEffect(() => {
    navigate(currentPath)
  },[])

  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('currentPath', currentPath);
  }, [currentPath]);
  
  
  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(removeData())
    navigate("/login");
    setanchorlEl(null);
  };


  return (
    <>
      <Box
        sx={{
          boxShadow: '0px 8px 18px 0px rgba(0, 0, 0, 0.1)',
          width: "100%",
          height: "120px",
          backgroundColor: "#27bac4",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: { md: "60px", xs: "20px" },
        }}
      >
        <Box>
          <LogoIcon />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ marginRight: "20px" }}>
            <Typography variant="h6_1" sx={{ color: "#FFF" }}>
              Admin Dashboard
            </Typography>
          </Box>
          <Box
            onClick={(e) => {
              setanchorlEl(e.currentTarget);
            }}
            px={1.3}
            sx={{
              width: "fit-content",
              height: "40px",
              borderRadius: "8px",
              paddding: "10px !important",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
              cursor : 'pointer'
            }}
          >
            <Box component="img" src={UserCircleIcon}></Box>
            <Typography variant="h9_3" color="#017BCC">
              {adminData.userName}
            </Typography>
            <DownArrowIcon fill="#017BCC" />
          </Box>
        </Box>
      </Box>
      <Grid container sx={{ height: "calc(100vh - 120px)" }}>
        <Grid
          item
          lg={2.1}
          md={3}
          sm={4}
          xs={12}
          sx={{
            position: "fixed",
            height: "100%",
            borderRight: "2px solid rgb(229, 231, 235)",
            // px: { md: 4, xs: 4 },
            overflowY: "auto",
            width : '22%'
          }}
        >
          <Stack direction="column" gap={1} py={4}>
          {navItems.map((item) => (
                <Stack
                  key={item.name}
                  onClick={() => {
                    navigate(item.path);
                    setCurrentPath(item.path);
                  }}
                  direction="row"
                  alignItems="center"
                  gap="10px"
                  sx={{
                    height: "45px",
                    width: "100%",
                    backgroundColor: currentPath === item.path ? "rgba(245, 248, 250, 1)" : "",
                    // borderRadius: "8px",
                    color: currentPath === item.path ? "rgba(26, 36, 97, 1)" : "#737373",
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{width : '35%', alignItems : 'center', justifyContent : 'center', display : 'flex'}}>
                  {React.isValidElement(item.icon) &&
                React.cloneElement(item.icon, {
                  style: {
                    fill: currentPath === item.path ? "rgba(26, 36, 97, 1)" : "#737373",
                    color: currentPath === item.path ? "rgba(26, 36, 97, 1)" : "#737373",
                  },
                })}
                  </Typography>
                  <Typography variant="h9_1">{item.name}</Typography>
                </Stack>
              ))}
          </Stack>
        </Grid>
        <Grid
          item
          lg={9.9}
          md={9}
          sm={8}
          xs={12}
          sx={{
            marginLeft: { lg: "17.5%", md: "25%", sm: "33%", xs: 0 },
            height: "calc(100vh - 120px)",
            overflowY: "auto",
            py: 4,
            px: 3,
          }}
        >
          <Box>
            <Outlet />
          </Box>
        </Grid>
      </Grid>
      <Menu
        open={open}
        anchorEl={anchorlEl}
        onClose={() => setanchorlEl(null)}
        sx={{ "& .MuiAvatar-root": { p: 2 }, mt: 1 }}
      >
        <MenuItem
          gap="10px"
          onClick={() => {
            setanchorlEl(null);
          }}
        >
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <Typography color="#017BCC" variant="h7_3">
            Account
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <Typography color="#017BCC" variant="h7_3">
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
