import { Divider, Typography,TableContainer,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
 } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";
import axios from 'axios';

function ChargesMain() {

    const [deposits, setDeposits] = useState([])
    const [mainAmounts, setMainAmounts] = useState([])

    const [showCharges, setShowCharges] = useState(() => {
        const localValue = localStorage.getItem("charges");
        return localValue !== null ? JSON.parse(localValue) : 'deposit';
    });

    const navigate = useNavigate()


    const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        localStorage.setItem("charges", JSON.stringify(showCharges));
      }, [showCharges]);

      const [navData, setNavData] = useState([
        {
          id: 1,
          name: 'Deposits',
          show: 'deposit',
        },
        {
          id: 2,
          name: 'Main',
          show: 'main',
        },
      ]);

      const stripHtml = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
      };

      let deleteData = {
        label: showCharges === 'deposit'
          ? "Confirm Delete Deposits"
          : "Confirm Delete Main Amount",
        descriptionOne: showCharges === 'deposit'
          ? "Please confirm that you wish to delete the deposit."
          : "Please confirm that you wish to delete the main amounts.",
        buttonOne: {
          variant: "outlined",
          color: "#0E5E84",
          backgroundColor: "#FFFFFF",
          text: "Cancel",
        },
        buttonTwo: {
          variant: "contained",
          color: "#FFFFFF",
          backgroundColor: "#912018",
          text: "Confirm",
        },
      };

      const handleClose = () => {
        setDeleteModal(null);
        setDeleteId(null);
      };

      const fetchDepositData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}get-deposits`
          );
          setDeposits(response.data.data || []);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
    
      const fetchMainAmountData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}get-main-amounts`
          );
          setMainAmounts(response.data.data || []);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
    
      useEffect(() => {
        fetchDepositData();
        fetchMainAmountData();
      }, []);

      const deleteOtherService = async () => {
        try {
          if (showCharges === 'deposit') {
            await axios.delete(
              `${process.env.REACT_APP_API_URL}delete-deposit/${deleteId}`
            );
            fetchDepositData();
            setDeleteModal(null);
            setDeleteId(null);
          } else if (showCharges === 'main') {
            await axios.delete(
              `${process.env.REACT_APP_API_URL}delete-main-amount/${deleteId}`
            );
            fetchMainAmountData();
            setDeleteModal(null);
            setDeleteId(null);
          }
        } catch (error) {
          console.log(error);
        }
      };

      const renderDeposits = () => {
        return (
          <TableBody>
                  {deposits.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ borderTop: ".5px solid #737373" }}
                    >
                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.title || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                       {stripHtml(item?.description || "-").slice(0, 20)}...
                       {/* {item?.description || "-"} */}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                        S$  {item?.amount || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/edit-deposit/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <EditIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
        )
      }

      const renderMainAmount = () => {
        return (
          <TableBody>
                  {mainAmounts.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ borderTop: ".5px solid #737373" }}
                    >
                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.title || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                       {stripHtml(item?.description || "-").slice(0, 20)}...
                       {/* {item?.description || "-"} */}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                        S$  {item?.amount || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/edit-main-amount/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <EditIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
        )
      }

      if(loading){
        return <p>Loading...</p>
      }
      
  return (
    <Box>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteOtherService}
          closeFunction={handleClose}
        />
      )}
        <Typography sx={{fontSize : '22px', fontWeight : 700}}>Charges</Typography>
        <Divider sx={{mt : 2, mb : 3}}/>

        <Box sx={{ display: "flex", gap: "20px" }}>
        {navData.map((item) => (
                <Box
                    key={item.id}
                    onClick={() => setShowCharges(item.show)}
                    sx={{
                    width: "100%",
                    borderTop: showCharges === item.show ? "3px solid #27bac4" : "3px solid gray",
                    borderRadius: "0px",
                    textAlign: "left",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#f0f0f0",
                    },
                    padding: "3px",
                    transition: "ease-in",
                    transitionDuration: "250ms",
                    }}
                >
                    <Typography
                    sx={{
                        pt: 0,
                        color: showCharges === item.show ? "#27bac4" : "gray",
                        fontWeight: 400,
                        fontSize: "18px",
                    }}
                    >
                    {item.name}
                    </Typography>
                </Box>
                ))}
        </Box>
                
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px 0px 20px 0px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate(showCharges === 'deposit' ? "/Admin/add-deposit" : '/Admin/add-main-amount')}
              >
                Add
              </Button>
            </Box>

            <TableContainer sx={{ borderRadius: "8px" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h9_1" color="#333333">
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {showCharges === 'deposit' && renderDeposits()}
                {showCharges === 'main' && renderMainAmount()}

              </Table>
            </TableContainer>
            
    </Box>
  )
}

export default ChargesMain
