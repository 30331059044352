import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";

// Helper function to strip HTML tags
const stripHtmlTags = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export default function NewsList() {
  
  const [page, setPage] = useState(0); // Initialize to 0 for zero-based indexing
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [totalItems, setTotalItems] = useState(0); // State to hold the total count of items

  let deleteData = {
    label: "Confirm Delete News ",
    descriptionOne: "Please confirm that you wish to delete the News.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#912018",
      text: "Confirm",
    },
  };

  const navigate = useNavigate();
  const [NewsData, setNewsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");

  const getAllNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}getAllNews?page=${
          page + 1
        }&limit=${rowsPerPage}`
      );
      setTotalItems(response.data.pagination.totalItems || 0)
      setNewsData(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setDeleteModal(null);
    setDeleteId(null);
  };

  const deleteNews = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}deleteNews/${deleteId}`
      );
      getAllNews();
      setDeleteModal(null);
      setDeleteId(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSeeMore = (description) => {
    setSelectedDescription(description);
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };

  useEffect(() => {
    getAllNews();
  }, [page]);

  useEffect(() => {
    getAllNews();
  }, [rowsPerPage]);

  return (
    <>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteNews}
          closeFunction={handleClose}
        />
      )}

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h7_1">News</Typography>
        <Button onClick={() => navigate("/Admin/NewsAdd")} variant="contained">
          Add
        </Button>
      </Stack>
      <Divider sx={{ mb: 3, mt: 2 }} />
      <TableContainer sx={{ borderRadius: "8px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Image
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h9_1" color="#333333">
                  Title
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                {" "}
                <Typography variant="h9_1" color="#333333">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {NewsData.map((item, index) => (
              <TableRow
                key={item.name}
                sx={{ borderTop: ".5px solid #737373" }}
              >
                <TableCell>
                  <Box
                    component="img"
                    src={process.env.REACT_APP_FILE_PATH + item?.img}
                    sx={{ width: "250px" }}
                  ></Box>
                </TableCell>
                <TableCell sx={{ minWidth: "200px" }}>
                  <Typography variant="h9_4" color="#333333">
                    {item?.title || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ maxWidth: "200px" }}>
                  <Button
                    onClick={() => handleSeeMore(item?.description)}
                    variant="contained"
                    // sx={{ ml: 1 }}
                  >
                    See Description
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => navigate(`/Admin/edit-news/${item._id}`)}
                    sx={{ marginRight: "10px" }}
                  >
                    <EditIcon fill="black" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteId(item._id);
                      setDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={totalItems} 
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>

      {/* Dialog for full description */}
      <Dialog
        open={!!selectedDescription}
        onClose={() => setSelectedDescription("")}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Full Description</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <Typography variant="body1">{parse(selectedDescription)}</Typography>
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button
            onClick={() => setSelectedDescription("")}
            color="primary"
            variant="contained"
            sx={{ margin: "10px", padding: "10px 20px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
