import MainIcon from "../Icons/Menus/main.svg";
import IntroIcon from "../Icons/Menus/intro.svg";
import LinksIcon from "../Icons/Menus/links.svg";
import NewsIcon from "../Icons/Menus/news.svg";
import VisitorIcon from "../Icons/Menus/visitor.svg";
import ServicesIcon from "../Icons/Menus/services.svg";
import ApplicationIcon from "../Icons/Menus/application.svg";
import Step1 from "../Icons/StepIcons/step1.svg";
import Step2 from "../Icons/StepIcons/step2.svg";
import Step3 from "../Icons/StepIcons/step3.svg";
import Step4 from "../Icons/StepIcons/step4.svg";
import Step5 from "../Icons/StepIcons/step5.svg";
import Step6 from "../Icons/StepIcons/step6.svg";
import AccountabilityIcon from "../Icons/Services/AccountabilityIcon.svg";
import BestIcon from "../Icons/Services/BestIcon.svg";
import OptionalServicesIcon from "../Icons/Services/OptionalServicesIcon.svg";
import PositiveExperienceIcon from "../Icons/Services/PositiveExperienceIcon.svg";

import BellIcon  from "../Icons/BellIcon.svg";
import RightUpArrowIcon  from "../Icons/rightUpArrow.svg";
import Course_trainingIcon  from "../Icons/Announcement/course_training.svg";
import Dental_appointmentIcon  from "../Icons/Announcement/dental_appointment.svg";
import English_trialclaIcon  from "../Icons/Announcement/english_trial_cls.svg";
import Extracurricular_activitiesIcon  from "../Icons/Announcement/extracurricular_activities.svg";
import Rent_paymentIcon  from "../Icons/Announcement/rent_payment.svg";
import Repair_applicationIcon  from "../Icons/Announcement/repair_application.svg";
import Weekend_activitiesIcon  from "../Icons/Announcement/weekend_activities.svg";
import University_tutionIcon  from "../Icons/Announcement/university_tution.svg";
import Subject_tutionIcon  from "../Icons/Announcement/subject_tution.svg";
import CelebrationIcon from '@mui/icons-material/Celebration';
import LoginIcon from '@mui/icons-material/Login';

export const menus = [
  {
    id: 1,
    nav_bar_name: "Main",
    isdrobdown: 0,
    uri_link: "/",
    icon: MainIcon,
  },
  {
    id: 2,
    nav_bar_name: "Intro",
    isdrobdown: 0,
    uri_link: "/intro",
    icon: IntroIcon,
  },
  {
    id: 3,
    nav_bar_name: "Application",
    isdrobdown: 0,
    uri_link: "/application",
    icon: ApplicationIcon,
  },
  {
    id: 4,
    nav_bar_name: "Services",
    isdrobdown: 0,
    uri_link: "/services",
    icon: ServicesIcon,
  },
  {
    id: 5,
    nav_bar_name: "News",
    isdrobdown: 0,
    uri_link: "/news",
    icon: NewsIcon,
  },
  {
    id: 6,
    nav_bar_name: "Visitor",
    isdrobdown: 0,
    uri_link: "/visitor",
    icon: VisitorIcon,
  },
  {
    id: 7,
    nav_bar_name: "Links",
    isdrobdown: 0,
    uri_link: "/links",
    icon: LinksIcon,
  },
];

export const Steps = [
  {
    id: 1,
    name: "Step 1",
    Description: "<p>Choose Accommodation Type</p><br>",
    icon: Step1,
  },
  {
    id: 2,
    name: "Step 2",
    Description: "<p>Fill Out The Information Form</p><br>",
    icon: Step1,
  },
  {
    id: 3,
    name: "Step 3",
    Description: "<p>Pay Deposit</p><br><br>",
    icon: Step3,
  },
  {
    id: 4,
    name: "Step 4",
    Description: "<p>Pending Confirmation</p><br><br>",
    icon: Step4,
  },
  {
    id: 5,
    name: "Step 5",
    Description: "<p>Sign The Contract & Pay The Rent</p><br>",
    icon: Step5,
  },
  {
    id: 6,
    name: "Step 6",
    Description: "<p>Fly to Singapore & Start A New Life</p><br>",
    icon: Step6,
  },
];

export const ServicesSteps = [
  {
    id: 1,
    name: "Accountability",
    icon: AccountabilityIcon,
  },
  {
    id: 2,
    name: "Best in class",
    icon: BestIcon,
  },
  {
    id: 3,
    name: "Optional Services",
    icon: OptionalServicesIcon,
  },
  {
    id: 4,
    name: "Positive experience",
    icon: PositiveExperienceIcon,
  },
];

export const AnnounceMentList = [
  {
    id : 1,
    name : 'Vinga Hall<br> Application',
    icon : ApplicationIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 2,
    name : 'Rent<br> Payment',
    icon : Rent_paymentIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 3,
    name : 'Festival<br> Activities',
    icon : CelebrationIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 4,
    name : 'Weekend<br> Activities',
    icon : Weekend_activitiesIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 5,
    name : 'English<br> Trail Class',
    icon : English_trialclaIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 6,
    name : 'Subject<br> Tuition',
    icon : Subject_tutionIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 7,
    name : 'University<br> Tuition',
    icon : University_tutionIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 8,
    name : 'Extracurricular<br> Activities',
    icon : Extracurricular_activitiesIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 9,
    name : 'Q & M Dental<br> Appointment',
    icon : Dental_appointmentIcon,
    arrow : RightUpArrowIcon
  },
  {
    id : 10,
    name : 'Repair<br> Application',
    icon : Repair_applicationIcon,
    arrow : RightUpArrowIcon
  }
]