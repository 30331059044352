import { Divider, Typography, Button,TableContainer,
    Table,
    IconButton,
    TableHead,
    TableRow,
    TableCell,
    TableBody, } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import axios from "axios";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


function Queries() {
    const navigate = useNavigate()

    const [queryData, setQueryData] = useState([])

    const fetchQueries = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}get-contacts`).then((response) => {
            setQueryData(response?.data?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchQueries()
    }, [])

    const [deleteModal, setDeleteModal] = useState(null);
    const [deleteId, setDeleteId] = useState(null);

    let deleteData = {
      label: "Confirm Delete Query",
      descriptionOne: "Please confirm that you wish to delete the Query.",
      buttonOne: {
        variant: "outlined",
        color: "#0E5E84",
        backgroundColor: "#FFFFFF",
        text: "Cancel",
      },
      buttonTwo: {
        variant: "contained",
        color: "#FFFFFF",
        backgroundColor: "#912018",
        text: "Confirm",
      },
    };

    const handleClose = () => {
      setDeleteModal(null);
      setDeleteId(null);
    };
  
    const deleteQuery = async () => {
      try {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}delete-contact/${deleteId}`
          );
          fetchQueries();
          setDeleteModal(null);
          setDeleteId(null);
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <Box>
       {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteQuery}
          closeFunction={handleClose}
        />
      )}
        <Typography sx={{fontSize : '1.2rem', fontWeight : 700}}>Queries</Typography>
        <Divider sx={{mt : 2, mb : 3}}/>

        <TableContainer sx={{ borderRadius: "3px" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{background : '#f2f3f2'}}>
                    <TableCell>
                      <Typography variant="h9_1" color="#333333">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Mobile
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Query subject
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography variant="h9_1" color="#333333">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {queryData.length === 0 ? (
                    <TableCell colSpan={4}>
                    <Typography variant="h9_4" color="#333333">
                      No data found
                    </Typography>
                  </TableCell>
                  ) : (queryData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ borderTop: ".5px solid #737373" }}
                    //   onClick={() => navigate(`/Admin/view-query/${item._id}`)}
                    >
                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                          {item?.name || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                            {item?.email || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="h9_4" color="#333333">
                            {item?.querySubject?.slice(0, 20) + '...' || "-"}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate(`/Admin/view-query/${item._id}`)
                          }
                          sx={{ marginRight: "10px" }}
                        >
                          <RemoveRedEyeIcon fill="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteId(item._id);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
              </Table>
            </TableContainer>

    </Box>
  )
}

export default Queries