// import React, {useState, useEffect} from 'react'
// import { Divider, Typography, Button,TableContainer,
//     Table,
//     IconButton,
//     TableHead,
//     TableRow,
//     TableCell,
//     TableBody,
//     TablePagination,
//   } from '@mui/material'
// import { Box } from '@mui/system'
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
// import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
// import AlertModal from "../../../CommonComponents/AlertModal";

// function CheckListMain() {
//     const navigate = useNavigate();

//     const [page, setPage] = useState(1); 
//     const [limit, setLimit] = useState(5);
//     const [rowsPerPage, setRowsPerPage] = useState(5); 

//     const [checklistData, setCheckListData] = useState([]);
//     const [loading, setLoading] = useState(true)
//     const [deleteModal, setDeleteModal] = useState(null);
//     const [deleteId, setDeleteId] = useState(null);

//   let deleteData = {
//     label: "Confirm Delete Checklist",
//     descriptionOne: "Please confirm that you wish to delete the checklist.",
//     buttonOne: {
//       variant: "outlined",
//       color: "#0E5E84",
//       backgroundColor: "#FFFFFF",
//       text: "Cancel",
//     },
//     buttonTwo: {
//       variant: "contained",
//       color: "#FFFFFF",
//       backgroundColor: "#912018",
//       text: "Confirm",
//     },
//   };

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}get-check-list?page=${page}&limit=${limit}`
//       );
//       console.log("Get all checklist:", response.data.data);
//       setCheckListData(response.data.data || []);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData()
//   }, [page, limit]);

//   if (loading) {
//     return <Typography>Loading...</Typography>;
//   }

//   const handleClose = () => {
//     setDeleteModal(null);
//     setDeleteId(null);
//   };

//   const deleteCheckList = async () => {
//     try {
//         await axios.delete(
//           `${process.env.REACT_APP_API_URL}delete-check-list/${deleteId}`
//         );
//         fetchData();
//         setDeleteModal(null);
//         setDeleteId(null);
//     } catch (error) {
//       console.log(error);
//     }
//   };

 

//   // Handle page change
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   // Handle rows per page change
//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0); // Reset to first page when rows per page change
//   };

  
//   return (
//       <>
//       {deleteModal && (
//         <AlertModal
//           modalDatas={deleteData}
//           confirmFunction={deleteCheckList}
//           closeFunction={handleClose}
//         />
//       )}

//     <Box sx ={{display :'flex', justifyContent : 'space-between', mb : 2}}>
//     <Typography sx={{fontSize : '22px', fontWeight : 600}}>Checklist</Typography>
//     <Button variant='contained' onClick={() => navigate('/Admin/AddCheckList')}>Add</Button>
//     </Box>
//     <Divider sx={{mb : 3.5}}/>
//     <TableContainer sx={{ borderRadius: "5px", border : '0px',boxShadow : '0px 0px 25px 8px #f4f4f4' }}>
//               <Table sx={{ minWidth: 650 }} aria-label="simple table">
//                 <TableHead>
//                   <TableRow sx={{backgroundColor : 'white'}}>
//                     <TableCell sx={{borderBottom : '0.5px solid #dcdddc', py : 2.7}}>
//                       <Typography variant="h9_1" color="#333333">
//                         Item
//                       </Typography>
//                     </TableCell>
//                     <TableCell sx={{borderBottom : '0.5px solid #dcdddc', py : 2.7}}>
//                       {" "}
//                       <Typography variant="h9_1" color="#333333">
//                         Price
//                       </Typography>
//                     </TableCell>
//                     <TableCell sx={{borderBottom : '0.5px solid #dcdddc', py : 2.7}}>
//                       {" "}
//                       <Typography variant="h9_1" color="#333333">
//                         Actions
//                       </Typography>
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody sx={{height : '50vh'}}>
//                 {checklistData.map((item, index) => (
//             <TableRow
//               key={index}
//               sx={{
//                 border: "0px",
//                 backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white",
//                 overflowY: "auto",
//               }}
//             >
//               <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
//                 <Typography variant="h9_4" color="#333333">
//                   {item?.item || "-"}
//                 </Typography>
//               </TableCell>
//               <TableCell sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}>
//                 <Typography variant="h9_4" color="#333333">
//                   S$ {item?.price || "-"}
//                 </Typography>
//               </TableCell>
//               <TableCell sx={{ borderBottom: "0.5px solid #dcdddc" }}>
//                 <IconButton
//                   onClick={() => navigate(`/Admin/edit-checklist/${item._id}`)}
//                   sx={{ marginRight: "10px" }}
//                 >
//                   <EditIcon fill="black" />
//                 </IconButton>
//                 <IconButton
//                   onClick={() => {
//                     setDeleteId(item._id);
//                     setDeleteModal(true);
//                   }}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
      
//       <TablePagination
//         component="div"
//         count={checklistData.length} 
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         rowsPerPageOptions={[5, 10, 25]} 
//       />
//     </TableContainer>
//     </>
//   )
// }

// export default CheckListMain


import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  Button,
  TableContainer,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg";
import AlertModal from "../../../CommonComponents/AlertModal";

function CheckListMain() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0); // Initialize to 0 for zero-based indexing
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

  const [checklistData, setCheckListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0); // State to hold the total count of items
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  let deleteData = {
    label: "Confirm Delete Checklist",
    descriptionOne: "Please confirm that you wish to delete the checklist.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#912018",
      text: "Confirm",
    },
  };

  // Function to fetch paginated data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-check-list?page=${
          page + 1
        }&limit=${rowsPerPage}`
      );
      console.log("Get all checklist:", response.data);
      setCheckListData(response.data.data || []);
      setTotalItems(response.data.pagination.totalItems || 0);
      setLoading(false) 
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };

  // Fetch data whenever page or rowsPerPage changes
  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [rowsPerPage]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };

  const handleClose = () => {
    setDeleteModal(null);
    setDeleteId(null);
  };

  const deleteCheckList = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}delete-check-list/${deleteId}`
      );
      fetchData();
      setDeleteModal(null);
      setDeleteId(null);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      {deleteModal && (
        <AlertModal
          modalDatas={deleteData}
          confirmFunction={deleteCheckList}
          closeFunction={handleClose}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
          Checklist
        </Typography>
        <Button
          variant='contained'
          onClick={() => navigate("/Admin/AddCheckList")}
          sx={{background : 'white', color : 'blue', border : '0.5px solid #a6d5fa',fontWeight : 700, boxShadow : '0px 0px 30px 20px #f4f4f4', borderRadius : '5px', ':hover' : {background : 'white', color : 'blue', border : '0.5px solid #a6d5fa',fontWeight : 700}}}
        >
          Add
        </Button>
      </Box>
      {/* <Divider sx={{ mb: 3.5 }} /> */}
      <TableContainer
        sx={{
          borderRadius: "5px",
          border: "1px solid #e5e5e5",
          boxShadow: "0px 0px 30px 20px #f4f4f4",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "white" }}>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                <Typography sx={{fontSize : '1.2rem', fontWeight : 600}} color="#333333">
                  Product
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                <Typography sx={{fontSize : '1.2rem', fontWeight : 600}} color="#333333">
                  Price
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "0.5px solid #dcdddc", py: 2.7 }}>
                <Typography sx={{fontSize : '1.2rem', fontWeight : 600}} color="#333333">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: "50vh" }}>
            {checklistData.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  border: "0px",
                  backgroundColor: index % 2 === 0 ? "#f8f8f8" : "white", 
                }}
              >
                <TableCell
                  sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}
                >
                  <Typography variant="h9_4">
                    {item?.item || "-"}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ minWidth: "200px", borderBottom: "0.5px solid #dcdddc" }}
                >
                  <Typography variant="h9_4">
                    S$ {item?.price || "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: "0.5px solid #dcdddc" }}>
                  <IconButton
                    onClick={() => navigate(`/Admin/edit-checklist/${item._id}`)}
                    sx={{ marginRight: "10px" }}
                  >
                    <EditIcon fill="black" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteId(item._id);
                      setDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={totalItems} 
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
    </>
  );
}

export default CheckListMain;
